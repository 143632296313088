import React from 'react';
import Sidebar from '../components/Sidebar';

const EVCPlayground: React.FC = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">EVC Playground</h1>
      <p>EV Charging Station testing environment coming soon...</p>
    </div>
  );
};

export default EVCPlayground;
