import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import {
  ShieldCheckIcon,
  BoltIcon,
  ChartBarIcon,
  CubeTransparentIcon,
} from '@heroicons/react/24/outline';

const features = [
  {
    title: 'Protocol Compliance',
    description: 'Ensure your charging infrastructure follows OCPP standards with our comprehensive testing suite.',
    icon: ShieldCheckIcon,
  },
  {
    title: 'Security Testing',
    description: 'Protect your charging network with advanced security testing and vulnerability assessments.',
    icon: BoltIcon,
  },
  {
    title: 'Performance Monitoring',
    description: 'Track and analyze your charging stations performance in real-time.',
    icon: ChartBarIcon,
  },
  {
    title: 'Integration Support',
    description: 'Seamlessly integrate with existing systems and protocols.',
    icon: CubeTransparentIcon,
  },
];

const Landing = () => {
  return (
    <Box>
      {/* Navigation */}
      <Box className="bg-white shadow-sm">
        <Container maxWidth="lg">
          <Box className="flex justify-between items-center py-4">
            <img src="/logo.png" alt="Safe2Plug Logo" className="h-8" />
            <Box className="space-x-4">
              <Button
                component={Link}
                to="/login"
                variant="outlined"
                className="border-primary-600 text-primary-600"
              >
                Login
              </Button>
              <Button
                component={Link}
                to="/register"
                variant="contained"
                className="bg-primary-600"
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Hero Section */}
      <Box className="bg-gradient-to-r from-primary-600 to-primary-800 text-white py-20">
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" className="font-bold mb-4">
                Safe2Plug Platform
              </Typography>
              <Typography variant="h5" className="mb-6 text-gray-100">
                The Complete Solution for EV Charging Infrastructure Testing and Compliance
              </Typography>
              <Button
                component={Link}
                to="/register"
                variant="contained"
                size="large"
                className="bg-white text-primary-600 hover:bg-gray-100"
              >
                Get Started
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <img
                src="/hero-image.png"
                alt="Platform Preview"
                className="w-full rounded-lg shadow-xl"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box className="py-20 bg-gray-50">
        <Container maxWidth="lg">
          <Typography variant="h3" className="text-center mb-12 font-semibold">
            Why Choose Safe2Plug?
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <Card className="h-full hover:shadow-lg transition-shadow">
                  <CardContent className="text-center p-6">
                    <feature.icon className="w-12 h-12 mx-auto mb-4 text-primary-600" />
                    <Typography variant="h6" className="mb-2">
                      {feature.title}
                    </Typography>
                    <Typography color="text.secondary">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* CTA Section */}
      <Box className="bg-primary-600 text-white py-16">
        <Container maxWidth="lg">
          <Box className="text-center">
            <Typography variant="h4" className="mb-4">
              Ready to ensure your charging infrastructure?
            </Typography>
            <Typography variant="body1" className="mb-8">
              Join the leading companies using Safe2Plug Platform
            </Typography>
            <Button
              component={Link}
              to="/register"
              variant="contained"
              size="large"
              className="bg-white text-primary-600 hover:bg-gray-100"
            >
              Start Free Trial
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Footer */}
      <Box className="bg-gray-900 text-gray-300 py-12">
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" className="mb-4">
                Safe2Plug
              </Typography>
              <Typography variant="body2">
                Leading the way in EV charging infrastructure testing and compliance.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" className="mb-4">
                Contact
              </Typography>
              <Typography variant="body2">
                Email: info@safe2plug.com<br />
                Phone: +1 (555) 123-4567
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" className="mb-4">
                Follow Us
              </Typography>
              <Box className="space-x-4">
                <Button color="inherit">Twitter</Button>
                <Button color="inherit">LinkedIn</Button>
                <Button color="inherit">GitHub</Button>
              </Box>
            </Grid>
          </Grid>
          <Box className="mt-8 pt-8 border-t border-gray-800 text-center">
            <Typography variant="body2">
              © {new Date().getFullYear()} Safe2Plug. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Landing;
