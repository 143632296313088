import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { ArrowTopRightOnSquareIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import api from '../api/axios';

interface SchemaMessage {
  name: string;
  type: string;
  direction: 'CP -> CMS' | 'CMS -> CP';
  description?: string;
}

interface SchemaData {
  json_schemas: SchemaMessage[];
  soap_schemas: SchemaMessage[];
}

interface SchemaDetail {
  name: string;
  type: string;
  content: string;
}

const OCPP_VERSIONS = ['OCPP 1.6', 'OCPP 2.0.1'];

const OcppSchemas = () => {
  const [activeVersion, setActiveVersion] = useState('OCPP 1.6');
  const [schemaData, setSchemaData] = useState<SchemaData>({ json_schemas: [], soap_schemas: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [schemaDetail, setSchemaDetail] = useState<SchemaDetail | null>(null);
  const [detailLoading, setDetailLoading] = useState(false);

  useEffect(() => {
    fetchSchemas();
  }, []);

  const fetchSchemas = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/ocpp-schemas/');
      setSchemaData(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load OCPP schemas');
      console.error('Error fetching schemas:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchSchemaDetail = async (schemaName: string) => {
    try {
      setDetailLoading(true);
      const response = await api.get(`/api/ocpp-schemas/${schemaName}/`);
      setSchemaDetail(response.data);
    } catch (err) {
      console.error('Error fetching schema detail:', err);
      setSchemaDetail(null);
    } finally {
      setDetailLoading(false);
    }
  };

  const handleVersionChange = (_: React.SyntheticEvent, newValue: string) => {
    setActiveVersion(newValue);
  };

  const getDirectionColor = (direction: string) => {
    return direction === 'CP -> CMS' ? 'primary' : 'success';
  };

  const getAllSchemas = () => {
    return [...schemaData.json_schemas, ...schemaData.soap_schemas];
  };

  const handleRowClick = async (messageName: string) => {
    if (expandedRow === messageName) {
      setExpandedRow(null);
      setSchemaDetail(null);
    } else {
      setExpandedRow(messageName);
      await fetchSchemaDetail(messageName);
    }
  };

  if (loading) {
    return (
      <Box className="p-6">
        <Typography>Loading schemas...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="p-6">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="p-6">
      <Typography variant="h5" className="mb-2">
        OCPP Schemas
      </Typography>
      <Typography variant="body2" color="text.secondary" className="mb-6">
        Browse and manage OCPP message schemas for different versions.
      </Typography>

      <Paper className="mb-6">
        <Tabs
          value={activeVersion}
          onChange={handleVersionChange}
          variant="scrollable"
          scrollButtons="auto"
          className="border-b border-gray-200"
        >
          {OCPP_VERSIONS.map((version) => (
            <Tab
              key={version}
              label={version}
              value={version}
              className="min-w-[120px]"
            />
          ))}
        </Tabs>
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          <TableHead className="bg-gray-50">
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>Message</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Direction</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAllSchemas().map((message) => (
              <React.Fragment key={message.name}>
                <TableRow 
                  hover 
                  onClick={() => handleRowClick(message.name)}
                  className="cursor-pointer"
                >
                  <TableCell padding="checkbox">
                    {expandedRow === message.name ? (
                      <ChevronUpIcon className="h-5 w-5" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {message.name}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={message.type}
                      color="default"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={message.direction}
                      color={getDirectionColor(message.direction)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{message.description || '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="p-0" colSpan={6}>
                    <Collapse in={expandedRow === message.name} timeout="auto" unmountOnExit>
                      <Box className="p-4 bg-gray-50">
                        {detailLoading ? (
                          <Typography>Loading schema details...</Typography>
                        ) : schemaDetail ? (
                          <pre className="whitespace-pre-wrap font-mono text-sm bg-white p-4 rounded border border-gray-200 overflow-x-auto">
                            {schemaDetail.content}
                          </pre>
                        ) : (
                          <Typography color="error">Failed to load schema details</Typography>
                        )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OcppSchemas;
