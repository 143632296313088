import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';

const Dashboard: React.FC = () => {
  const testData = {
    successful: 75,
    failed: 25
  };

  const companies = [
    {
      id: '1',
      name: 'ZES',
      logo: '/company-logos/zes.png',
      status: 'active',
      chargePoints: 150,
      lastActive: '2023-12-08',
    },
    {
      id: '2',
      name: 'Eşarj',
      logo: '/company-logos/esarj.png',
      status: 'active',
      chargePoints: 120,
      lastActive: '2023-12-09',
    },
    {
      id: '3',
      name: 'Sharz',
      logo: '/company-logos/sharz.png',
      status: 'pending',
      chargePoints: 80,
      lastActive: '2023-12-07',
    },
    {
      id: '4',
      name: 'Voltrun',
      logo: '/company-logos/voltrun.png',
      status: 'inactive',
      chargePoints: 45,
      lastActive: '2023-12-05',
    },
  ];

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'pending':
        return 'warning';
      case 'inactive':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ display: 'flex', bgcolor: '#f5f5f5', minHeight: '100vh' }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={3}>
          {/* Top row cards */}
          <Grid item xs={4}>
            <Card sx={{ boxShadow: 'none', bgcolor: 'white', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ mb: 1, color: '#666' }}>
                  Activity Summary
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Users
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ boxShadow: 'none', bgcolor: 'white', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ mb: 1, color: '#666' }}>
                  Last Login
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ boxShadow: 'none', bgcolor: 'white', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ mb: 1, color: '#666' }}>
                  Subscription Type & Account Status
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Recent Activity Section */}
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 'none', bgcolor: 'white', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ mb: 2, color: '#666' }}>
                  Recent Activity
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <Box
                      sx={{
                        width: 200,
                        height: 200,
                        borderRadius: '50%',
                        background: `conic-gradient(
                          #7E57C2 0% ${testData.successful}%,
                          #B39DDB ${testData.successful}% 100%
                        )`,
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: '#7E57C2', mb: 1 }}>
                      Successful test cases: {testData.successful}%
                    </Typography>
                    <Typography sx={{ color: '#B39DDB' }}>
                      Failed test cases: {testData.failed}%
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Companies Section */}
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 'none', bgcolor: 'white', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ mb: 2, color: '#666' }}>
                  Companies
                </Typography>
                <Grid container spacing={3}>
                  {companies.map((company) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
                      <Card sx={{ height: '100%' }}>
                        <CardContent>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <img src={company.logo} alt={company.name} style={{ width: 64, height: 64, marginBottom: 16 }} />
                            <Typography variant="h6" sx={{ mb: 1 }}>
                              {company.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                              {company.status}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <Box>
                                <Typography variant="body2" color="text.secondary">
                                  Charge Points
                                </Typography>
                                <Typography variant="h6">
                                  {company.chargePoints}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="body2" color="text.secondary">
                                  Last Active
                                </Typography>
                                <Typography variant="body1">
                                  {new Date(company.lastActive).toLocaleDateString()}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Stats Section */}
          <Grid item xs={12}>
            <Card sx={{ boxShadow: 'none', bgcolor: 'white', borderRadius: 2 }}>
              <CardContent>
                <Typography variant="subtitle1" sx={{ mb: 2, color: '#666' }}>
                  Stats
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  Number of scans so far
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Number of test cases executed so far
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
