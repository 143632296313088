import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  ShieldCheckIcon,
  CogIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ServerIcon,
  DocumentTextIcon,
  BeakerIcon,
  LockClosedIcon,
  UserCircleIcon,
  BuildingOffice2Icon,
} from '@heroicons/react/24/outline';

interface SidebarProps {
  onLogout: () => void;
}

interface NavItem {
  name: string;
  href?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  children?: NavItem[];
}

const navigation: NavItem[] = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
  {
    name: 'Companies',
    icon: BuildingOffice2Icon,
    children: [
      { name: 'All Companies', href: '/companies' },
      { name: 'Add Company', href: '/companies/add' }
    ]
  },
  {
    name: 'Protocol Compliance Hub',
    icon: ShieldCheckIcon,
    children: [
      {
        name: 'OCPP Compliance Module',
        children: [
          { name: 'OCPP Compliance Testing', href: '/compliance-hub/ocpp-compliance-testing' },
          { name: 'OCPP Schemas', href: '/compliance-hub/ocpp-schemas' }
        ]
      }
    ]
  },
  {
    name: 'Security Hub',
    icon: LockClosedIcon,
    children: [
      { name: 'OCPP - Blackbox', href: '/security-hub/ocpp-blackbox' },
      { name: 'OCPP - Whitebox', href: '/security-hub/ocpp-whitebox' }
    ]
  },
  {
    name: 'Utilities',
    icon: ServerIcon,
    children: [
      { name: 'CSMS', href: '/csms' },
      { name: 'CP', href: '/charge-point' }
    ]
  },
  {
    name: 'Reports',
    icon: DocumentTextIcon,
    href: '/reports'
  },
  { name: 'Pluggy', href: '/pluggy', icon: BeakerIcon },
  { name: 'Settings', href: '/settings', icon: CogIcon }
];

const NavItem = ({ item, depth = 0 }: { item: NavItem; depth?: number }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const isActive = item.href && location.pathname === item.href;
  const hasChildren = item.children && item.children.length > 0;

  return (
    <div>
      {item.href ? (
        <Link
          to={item.href}
          className={`flex items-center px-6 py-2 text-gray-600 hover:bg-gray-50 hover:text-primary-600 group relative ${
            isActive ? 'bg-primary-50 text-primary-600' : ''
          }`}
          style={{ paddingLeft: `${depth * 1 + 1.5}rem` }}
        >
          {item.icon && <item.icon className={`w-5 h-5 mr-3 ${isActive ? 'text-primary-600' : ''}`} />}
          <span className="text-sm">{item.name}</span>
          {isActive && <div className="absolute right-0 w-0.5 h-full bg-primary-600" />}
        </Link>
      ) : (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full flex items-center px-6 py-2 text-gray-600 hover:bg-gray-50 hover:text-primary-600"
          style={{ paddingLeft: `${depth * 1 + 1.5}rem` }}
        >
          {item.icon && <item.icon className="w-5 h-5 mr-3" />}
          <span className="text-sm flex-1 text-left">{item.name}</span>
          {hasChildren && (
            isOpen ? 
              <ChevronDownIcon className="w-4 h-4" /> : 
              <ChevronRightIcon className="w-4 h-4" />
          )}
        </button>
      )}
      {hasChildren && isOpen && item.children && (
        <div className="ml-4">
          {item.children.map((child, index) => (
            <NavItem key={child.name + index} item={child} depth={depth + 1} />
          ))}
        </div>
      )}
    </div>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ onLogout }) => {
  return (
    <div className="bg-white h-full border-r border-gray-200">
      <div className="flex flex-col h-full">
        {/* Navigation */}
        <nav className="flex-1 px-2 pt-4 space-y-1">
          <div className="pt-2" /> {/* Added top spacing */}
          {navigation.map((item, index) => (
            <NavItem key={item.name + index} item={item} />
          ))}
        </nav>

        {/* Profile */}
        <div className="p-4 border-t border-gray-200">
          <Link
            to="/profile"
            className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-50 hover:text-primary-600 rounded-md"
          >
            <UserCircleIcon className="h-5 w-5 mr-3" />
            <span className="text-sm">View Profile</span>
          </Link>
          <button
            onClick={onLogout}
            className="w-full mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
