import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Avatar,
  Chip,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  BuildingOffice2Icon,
  PlusIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';

interface Company {
  id: string;
  name: string;
  logo: string;
  status: 'active' | 'pending' | 'inactive';
  chargePoints: number;
  lastActive: string;
}

const Companies = () => {
  const companies: Company[] = [
    {
      id: '1',
      name: 'ZES',
      logo: '/company-logos/zes.png',
      status: 'active',
      chargePoints: 150,
      lastActive: '2023-12-08',
    },
    {
      id: '2',
      name: 'Eşarj',
      logo: '/company-logos/esarj.png',
      status: 'active',
      chargePoints: 120,
      lastActive: '2023-12-09',
    },
    {
      id: '3',
      name: 'Sharz',
      logo: '/company-logos/sharz.png',
      status: 'pending',
      chargePoints: 80,
      lastActive: '2023-12-07',
    },
    {
      id: '4',
      name: 'Voltrun',
      logo: '/company-logos/voltrun.png',
      status: 'inactive',
      chargePoints: 45,
      lastActive: '2023-12-05',
    },
  ];

  const getStatusColor = (status: Company['status']) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'pending':
        return 'warning';
      case 'inactive':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Box className="p-6">
      <Box className="flex justify-between items-center mb-6">
        <Box>
          <Typography variant="h5" className="font-medium mb-1">
            Companies
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Manage and monitor your connected companies
          </Typography>
        </Box>
        <Box className="flex gap-4">
          <TextField
            size="small"
            placeholder="Search companies..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyingGlassIcon className="w-5 h-5" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<PlusIcon className="w-5 h-5" />}
            className="bg-primary-600"
            href="/companies/add"
          >
            Add Company
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {companies.map((company) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
            <Card className="h-full hover:shadow-md transition-shadow">
              <CardContent>
                <Box className="flex flex-col items-center text-center">
                  <Avatar
                    src={company.logo}
                    alt={company.name}
                    className="w-16 h-16 mb-3"
                  >
                    <BuildingOffice2Icon className="w-8 h-8" />
                  </Avatar>
                  <Typography variant="h6" className="mb-1">
                    {company.name}
                  </Typography>
                  <Chip
                    label={company.status}
                    color={getStatusColor(company.status)}
                    size="small"
                    className="mb-4"
                  />
                  <Box className="w-full grid grid-cols-2 gap-4">
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Charge Points
                      </Typography>
                      <Typography variant="h6">
                        {company.chargePoints}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Last Active
                      </Typography>
                      <Typography variant="body1">
                        {new Date(company.lastActive).toLocaleDateString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Companies;
