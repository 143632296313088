import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import React from 'react';

// Layouts
import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';

// Pages
import Dashboard from './pages/Dashboard';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import EVCPlayground from './pages/EVCPlayground';
import CSMS from './pages/CSMS';
import OcppComplianceTesting from './pages/OcppComplianceTesting';
import OcppSchemas from './pages/OcppSchemas';
import SecurityOcppBlackbox from './pages/SecurityOcppBlackbox';
import Profile from './pages/Profile';
import Reports from './pages/Reports';
import Companies from './pages/Companies';
import Landing from './pages/Landing';

function App() {
  const hasToken = !!store.getState().auth.token;
  const [open, setOpen] = React.useState(true);  // Changed to true for default expanded state

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Root Route */}
          <Route path="/" element={
            hasToken ? <Navigate to="/dashboard" replace /> : <Landing />
          } />

          {/* Auth Routes */}
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Route>

          {/* Protected Routes */}
          <Route element={<MainLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/companies" element={<Companies />} />
            <Route path="/evc-playground" element={<EVCPlayground />} />
            <Route path="/csms" element={<CSMS />} />
            <Route path="/compliance-hub/ocpp-compliance-testing" element={<OcppComplianceTesting/>} />
            <Route path="/compliance-hub/ocpp-schemas" element={<OcppSchemas/>} />
            <Route path="/security-hub/ocpp-blackbox" element={<SecurityOcppBlackbox/>} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reports/*" element={<Reports />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
