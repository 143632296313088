import React from 'react';
import { Box, Typography } from '@mui/material';

const SecurityOcppBlackbox = () => {
  return (
    <Box className="p-6">
      <Typography variant="h5" className="mb-2">
        OCPP - Blackbox Security Testing
      </Typography>
      <Typography variant="body2" color="text.secondary" className="mb-6">
        Perform blackbox security testing on OCPP implementations.
      </Typography>
    </Box>
  );
};

export default SecurityOcppBlackbox;
