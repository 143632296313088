import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from '../api/axios';
import { StartTestResponse, TestStatusResponse } from '../types/ocpp';

interface TestCase {
  [key: string]: string[];
}

interface AvailableTests {
  csms_to_cp: TestCase;
  cp_to_csms: TestCase;
}

type Direction = 'csms_to_cp' | 'cp_to_csms';

const OcppComplianceTesting: React.FC = () => {
  const [projectName, setProjectName] = useState('');
  const [wsUri, setWsUri] = useState('');
  const [direction, setDirection] = useState<Direction>('cp_to_csms');
  const [availableTests, setAvailableTests] = useState<AvailableTests | null>(null);
  const [selectedTests, setSelectedTests] = useState<{ [key: string]: string[] }>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [testId, setTestId] = useState<string | null>(null);
  const [testStatus, setTestStatus] = useState<TestStatusResponse | null>(null);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);

  // Load available test cases on component mount
  useEffect(() => {
    const fetchAvailableTests = async () => {
      try {
        const response = await api.get('/api/ocpp-compliance-test/available-tests/');
        setAvailableTests(response.data);
        
        // Select all tests by default
        const allTests: { [key: string]: string[] } = {};
        const testsForDirection = response.data[direction];
        Object.entries(testsForDirection).forEach(([testClass, methods]) => {
          allTests[testClass] = methods as string[];
        });
        setSelectedTests(allTests);
      } catch (error) {
        console.error('Failed to fetch available tests:', error);
      }
    };

    fetchAvailableTests();
  }, [direction]);

  // Cleanup polling on unmount
  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [pollingInterval]);

  // Start polling when testId is set
  useEffect(() => {
    if (testId) {
      const startPolling = () => {
        const interval = setInterval(async () => {
          try {
            const response = await api.get(`/api/ocpp-compliance-test/${testId}/status/`);
            setTestStatus(response.data);
            
            // Stop polling if test is completed or failed
            if (response.data.status === 'completed' || response.data.status === 'failed') {
              if (pollingInterval) {
                clearInterval(pollingInterval);
                setPollingInterval(null);
              }
            }
          } catch (error) {
            console.error('Failed to fetch test status:', error);
            if (pollingInterval) {
              clearInterval(pollingInterval);
              setPollingInterval(null);
            }
          }
        }, 2000); // Poll every 2 seconds
        
        setPollingInterval(interval);
      };

      startPolling();
    }
  }, [testId]);

  const handleTestSelection = (testClass: string, testMethod: string) => {
    setSelectedTests(prev => {
      const updatedTests = { ...prev };
      if (!updatedTests[testClass]) {
        updatedTests[testClass] = [];
      }
      
      if (updatedTests[testClass].includes(testMethod)) {
        updatedTests[testClass] = updatedTests[testClass].filter(t => t !== testMethod);
        if (updatedTests[testClass].length === 0) {
          delete updatedTests[testClass];
        }
      } else {
        updatedTests[testClass] = [...updatedTests[testClass], testMethod];
      }
      
      return updatedTests;
    });
  };

  const handleSelectAllForClass = (testClass: string, methods: string[], checked: boolean) => {
    setSelectedTests(prev => {
      const updatedTests = { ...prev };
      if (checked) {
        updatedTests[testClass] = [...methods];
      } else {
        delete updatedTests[testClass];
      }
      return updatedTests;
    });
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked && availableTests) {
      const allTests: { [key: string]: string[] } = {};
      const testsForDirection = availableTests[direction as Direction];
      Object.entries(testsForDirection).forEach(([testClass, methods]) => {
        allTests[testClass] = methods as string[];
      });
      setSelectedTests(allTests);
    } else {
      setSelectedTests({});
    }
  };

  const isTestMethodSelected = (testClass: string, testMethod: string) => {
    return selectedTests[testClass]?.includes(testMethod) || false;
  };

  const isAllTestsSelectedForClass = (testClass: string, methods: string[]) => {
    return selectedTests[testClass]?.length === methods.length;
  };

  const isAllSelected = () => {
    if (!availableTests) return false;
    const testsForDirection = availableTests[direction as Direction];
    const totalMethods = Object.values(testsForDirection).reduce((acc: number, methods) => 
      acc + (methods as string[]).length, 0
    );
    const selectedMethods = Object.values(selectedTests).reduce((acc: number, methods) => 
      acc + methods.length, 0
    );
    return totalMethods === selectedMethods;
  };

  const handleStartTest = async () => {
    if (!selectedTests || Object.keys(selectedTests).length === 0) {
        setError('Please select at least one test case');
        return;
    }
    
    try {
        setLoading(true);
        setError(null);
        setTestId(null);
        setTestStatus(null);

        const testData = {
            project_name: projectName,
            uri: wsUri,
            direction: direction,
            test_cases: selectedTests,
            charge_point_id: 'CP01' // todo add charge point id
        };

        const response = await api.post<StartTestResponse>(
            '/api/ocpp-compliance-test/start/',
            testData,
        );

        setTestId(response.data.test_id);
    } catch (err: any) {
        setError(err.response?.data?.message || 'An error occurred while starting the test');
    } finally {
        setLoading(false);
    }
};

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        OCPP Compliance Testing
      </Typography>

      {/* Add Select All checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            checked={isAllSelected()}
            onChange={(e) => handleSelectAll(e.target.checked)}
            indeterminate={Object.keys(selectedTests).length > 0 && !isAllSelected()}
          />
        }
        label="Select All Test Cases"
        sx={{ mb: 2 }}
      />

      {/* Configuration Section */}
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Configuration
        </Typography>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="WebSocket URI"
              value={wsUri}
              onChange={(e) => setWsUri(e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Direction</InputLabel>
              <Select
                value={direction}
                label="Direction"
                onChange={(e) => setDirection(e.target.value as Direction)}
              >
                <MenuItem value="cp_to_csms">CP to CSMS</MenuItem>
                <MenuItem value="csms_to_cp">CSMS to CP</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Test Cases Selection */}
        {availableTests && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Test Cases
            </Typography>
            {Object.entries(availableTests[direction as Direction]).map(([testClass, methods]) => (
              <Accordion key={testClass}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAllTestsSelectedForClass(testClass, methods)}
                        onChange={(e) => handleSelectAllForClass(testClass, methods, e.target.checked)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    }
                    label={testClass}
                    onClick={(e) => e.stopPropagation()}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    {methods.map((method) => (
                      <Grid item xs={12} key={method}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isTestMethodSelected(testClass, method)}
                              onChange={() => handleTestSelection(testClass, method)}
                            />
                          }
                          label={method}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}

        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStartTest}
            disabled={loading || Object.keys(selectedTests).length === 0}
          >
            {loading ? 'Starting...' : 'Start Test'}
          </Button>
        </Box>
      </Paper>

      {/* Test Results Section */}
      {testStatus && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Test Results
          </Typography>
          
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="primary">
              Status: {testStatus.status}
            </Typography>
            {testStatus.error_message && (
              <Typography color="error">
                Error: {testStatus.error_message}
              </Typography>
            )}
          </Box>

          {testStatus.result && (
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Test Results:
              </Typography>
              {Object.entries(testStatus.result).map(([testClass, results]) => (
                <Accordion key={testClass}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{testClass}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      {Object.entries(results as Record<string, boolean>).map(([testName, passed]) => (
                        <Grid item xs={12} key={testName}>
                          <Typography
                            color={passed ? 'success.main' : 'error.main'}
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            {testName}: {passed ? '✓' : '✗'}
                          </Typography>
                          {testStatus.traffic?.[testClass]?.[testName] && (
                            <Box sx={{ mt: 1, mb: 2 }}>
                              <Typography variant="subtitle2">Traffic:</Typography>
                              {testStatus.traffic[testClass][testName].map((traffic: any, index: number) => (
                                <Box 
                                  key={index} 
                                  sx={{ 
                                    p: 1, 
                                    my: 0.5, 
                                    backgroundColor: 'grey.100',
                                    borderRadius: 1,
                                    fontFamily: 'monospace',
                                    fontSize: '0.875rem'
                                  }}
                                >
                                  <Typography variant="caption" display="block" color="textSecondary">
                                    {traffic.timestamp} - {traffic.direction}
                                  </Typography>
                                  <pre style={{ margin: 0, overflow: 'auto' }}>
                                    {JSON.stringify(traffic.message, null, 2)}
                                  </pre>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default OcppComplianceTesting;