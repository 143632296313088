import React from 'react';
import { Outlet, Navigate, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { RootState } from '../store';
import { logout } from '../store/slices/authSlice';
import {
  QuestionMarkCircleIcon,
  BellIcon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline';
import Sidebar from '../components/Sidebar';

const MainLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state: RootState) => state.auth);

  if (!token) {
    return <Navigate to="/login" />;
  }

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Top Bar */}
      <div className="fixed top-0 left-0 right-0 h-16 bg-[#7E57C2] z-50">
        <div className="flex items-center justify-between h-full px-4">
          <img src="/logo.png" alt="Safe2Plug Logo" width="160" />
          <div className="flex items-center gap-4">
            <button
              type="button"
              className="text-white hover:text-gray-200"
            >
              <QuestionMarkCircleIcon className="h-6 w-6" />
            </button>
            <button
              type="button"
              className="text-white hover:text-gray-200"
            >
              <BellIcon className="h-6 w-6" />
            </button>
            <Link
              to="/profile"
              className="text-white hover:text-gray-200"
            >
              <UserCircleIcon className="h-6 w-6" />
            </Link>
            <button
              onClick={handleLogout}
              className="text-white hover:text-gray-200"
            >
              <ArrowRightOnRectangleIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex h-screen pt-16">
        {/* Sidebar */}
        <div className="w-64 flex-shrink-0">
          <Sidebar onLogout={handleLogout} />
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-auto">
          <main className="p-6">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
